import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import FormContainer from "../../containers/fom-container/FormContainer";
import UseFullLinks from "../../containers/usefull-links/UseFullLinks";
import { selectLineManager } from "../../app/features/lineManagerSlice";

const EditPosition = () => {
  const positionId = useParams().positionId;
  const { teamDetails } = useSelector(selectLineManager);
  const identifiedPosition = teamDetails.find((p) => p.id === +positionId);

  return (
    <React.Fragment>
      <div className="container">
        <FormContainer
          isManager={identifiedPosition.isManager}
          isPositionEdited={true}
          positionId={positionId}
          jobTitle={identifiedPosition.positionTitle}
          jobFamilyGroup={identifiedPosition.jobFamilyGroup}
          jobFamily={identifiedPosition.jobFamily}
          jobProfileWithId={identifiedPosition.jobProfile}
          location={identifiedPosition.location}
          managementLevel={parseFloat(identifiedPosition.ManagementLevel)}
          coeOfPosition={identifiedPosition.coeOfPosition}
          isUnfilled={identifiedPosition.positionUnfilled}
        />
        <UseFullLinks />
      </div>
    </React.Fragment>
  );
};

export default EditPosition;
