import { useSelector } from "react-redux";

import ArrowDown from "../../components/icons/arrow-down/ArrowDown";
import ArrowUp from "../../components/icons/arrow-up/ArrowUp";
import ToolTip from "../tooltip/ToolTip";
import spanOfControlImage from "../../assets/spanOfControl.PNG";
import compressionImage from "../../assets/compression.PNG";
import orgLayerImage from "../../assets/orgLayer.PNG";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import { selectAuth } from "../../app/features/authSlice";

const spanOfControlDefinition = (
  <p>
    <span style={{ color: "#fde26a", fontWeight: "bold" }}>
      Span of control
    </span>{" "}
    is the number of subordinates that a Manager or Supervisor has in their
    immediate team.
  </p>
);

const compressionDefinition = (
  <p>
    <span style={{ color: "#fde26a", fontWeight: "bold" }}>Compression</span> is
    an average Management Level difference between direct reports and their
    Manager.
  </p>
);

const orgLayerDefinition = (
  <p>
    The{" "}
    <span style={{ color: "#fde26a", fontWeight: "bold" }}>
      organisational layer
    </span>{" "}
    of the direct reports shows at which layer in the organisation they 
    sit, counting from the CEO (layer 1) downwards.
  </p>
);

const OshTable = (props) => {
  const { managementLevelOfLineManger, adminParameters } =
    useSelector(selectAuth);
  const { managementLevels, noOfChanges } = useSelector(selectLineManager);
  const {
    spanOfControl,
    jgCompression,
    sameJGReportings,
    maxNoOfLayers,
    spanOfControlToBe,
    maxNoOfLayersToBe,
  } = props;

  const oshLegends = (
    <div>
      <p style={{ display: "block", textAlign: "left" }}>
        <span style={{ color: "#0ab15e", fontWeight: "bold" }}>GREEN</span> - In
        line with Shell organisational design standard ​
      </p>
      <p style={{ display: "block", textAlign: "left" }}>
        <span style={{ color: "#eb8705", fontWeight: "bold" }}>AMBER</span> -
        Below Shell organisational design standard
      </p>
      <p style={{ display: "block", textAlign: "left" }}>
        <span style={{ color: "#dd1d21", fontWeight: "bold" }}>RED</span>
        {"  "} - Significantly below Shell organisational design standard
      </p>
    </div>
  );

  const {
    compressionGREENGreaterAndEqualTo,
    compressionGREENLessAndEqualTo,
    compressionORANGEUPPERGreaterAndEqualTo,
    compressionORANGEUPPERLessAndEqualTo,
    compressionREDGreaterAndEqualTo,
    compressionREDLessAndEqualTo,
    compressionORANGELOWERGreaterAndEqualTo,
    compressionORANGELOWERLessAndEqualTo,
    layersGREENGreaterAndEqualTo,
    layersGREENLessAndEqualTo,
    layersORANGEGreaterAndEqualTo,
    layersORANGELessAndEqualTo,
    sameJobGroupReportingGREENGreaterAndEqualTo,
    spanOfControlGREENGreaterAndEqualTo,
    spanOfControlGREENLessAndEqualTo,
    spanOfControlORANGEGreaterAndEqualTo,
    spanOfControlORANGELessAndEqualTo,
    spanOfControlREDGreaterAndEqualTo,
    spanOfControlREDLessAndEqualTo,
  } = adminParameters;

  let compression = 0;
  const managementLevelsForCompressionCalculation = managementLevels.filter(
    (val) => val.managementLevel !== null
  );

  const values = managementLevelsForCompressionCalculation.map((val) =>
    val.managementLevel > managementLevelOfLineManger
      ? val.managementLevel - managementLevelOfLineManger
      : managementLevelOfLineManger - val.managementLevel
  );

  if (values.length !== 0) {
    compression = values.reduce((val, res) => val + res, 0) / values.length;
    compression = parseFloat(compression.toFixed(2));
  }

  const sameJGReportingsTobe = managementLevels.filter(
    (val) => val.managementLevel - managementLevelOfLineManger === 0
  ).length;

  let colorCodeSpanControlAsIs = null;
  if (
    spanOfControl <= spanOfControlREDLessAndEqualTo &&
    spanOfControl >= spanOfControlREDGreaterAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "red";
  } else if (
    spanOfControl <= spanOfControlORANGELessAndEqualTo &&
    spanOfControl >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "amber";
  } else if (
    spanOfControl >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControl <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "green";
  }

  let colorCodeSpanControlToBe = null;
  if (
    (spanOfControlToBe <= spanOfControlREDLessAndEqualTo &&
      spanOfControlToBe >= spanOfControlREDGreaterAndEqualTo) ||
    spanOfControlToBe === 0
  ) {
    colorCodeSpanControlToBe = "red";
  } else if (
    spanOfControlToBe <= spanOfControlORANGELessAndEqualTo &&
    spanOfControlToBe >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanControlToBe = "amber";
  } else if (
    spanOfControlToBe >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControlToBe <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanControlToBe = "green";
  }

  let colorCodeCompression = null;
  if (
    jgCompression >= compressionREDGreaterAndEqualTo &&
    jgCompression <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompression = "red";
  } else if (
    (jgCompression >= compressionORANGEUPPERGreaterAndEqualTo &&
      jgCompression <= compressionORANGEUPPERLessAndEqualTo) ||
    (jgCompression >= compressionORANGELOWERGreaterAndEqualTo &&
      jgCompression <= compressionORANGELOWERLessAndEqualTo)
  ) {
    colorCodeCompression = "amber";
  } else if (
    jgCompression >= compressionGREENGreaterAndEqualTo &&
    jgCompression <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompression = "green";
  }

  let colorCodeCompressionTobe = null;
  if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompressionTobe = "red";
  } else if (
    (compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo) ||
    (compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompressionTobe = "amber";
  } else if (
    compression >= compressionGREENGreaterAndEqualTo &&
    compression <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompressionTobe = "green";
  }

  let colorCodeMaxLayer = null;
  if (
    maxNoOfLayers >= layersGREENGreaterAndEqualTo &&
    maxNoOfLayers <= layersGREENLessAndEqualTo
  ) {
    colorCodeMaxLayer = "green";
  } else if (
    maxNoOfLayers >= layersORANGEGreaterAndEqualTo &&
    maxNoOfLayers <= layersORANGELessAndEqualTo
  ) {
    colorCodeMaxLayer = "amber";
  }

  let colorCodeSameJobReporting = "green";
  if (sameJGReportings !== null) {
    colorCodeSameJobReporting = "red";
  }

  let colorCodeSameJobReportingToBe = null;
  if (
    sameJGReportingsTobe !== sameJobGroupReportingGREENGreaterAndEqualTo &&
    noOfChanges !== 0
  ) {
    colorCodeSameJobReportingToBe = "red";
  } else if (
    sameJGReportingsTobe === sameJobGroupReportingGREENGreaterAndEqualTo &&
    noOfChanges !== 0
  ) {
    colorCodeSameJobReportingToBe = "green";
  }

  let warningMessage = null;
  if (sameJGReportings !== null) {
    warningMessage = (
      <div className="header-row">
        <span className="row parameter">Same Management Level reporting</span>
        <span className={`row as-is font-util ${colorCodeSameJobReporting}`}>
          {sameJGReportings ? sameJGReportings : 0}
        </span>
        <span
          className={`row to-be font-util ${colorCodeSameJobReportingToBe}`}
        >
          {sameJGReportingsTobe === sameJGReportings && noOfChanges === 0
            ? ""
            : sameJGReportingsTobe}
        </span>
      </div>
    );
  }

  let directionOfChangeSpanOfControl = null;
  if (spanOfControlToBe > spanOfControl) {
    directionOfChangeSpanOfControl = <ArrowUp />;
  } else if (spanOfControlToBe < spanOfControl) {
    directionOfChangeSpanOfControl = <ArrowDown />;
  }

  let directionOfChangeCompression = null;
  if (compression > jgCompression) {
    directionOfChangeCompression = <ArrowUp />;
  } else if (compression < jgCompression) {
    directionOfChangeCompression = <ArrowDown />;
  }

  return (
    <div className="table-osh">
      <section className="section-osh-table">
        <h3 className="header-table">Team structure health (Direct Reports)</h3>

        <div className="table">
          <div className="header-head">
            <span className="head parameter">DIMENSIONS</span>
            <span className="head as-is">AS-IS</span>
            <span className="head to-be">TO-BE</span>
          </div>
          <div className="header-row">
            <span className="row parameter">
              <ToolTip
                message={spanOfControlDefinition}
                source={spanOfControlImage}
                side="left"
              >
                SPAN OF CONTROL
              </ToolTip>
            </span>
            <span className={`row as-is font-util ${colorCodeSpanControlAsIs}`}>
              <ToolTip message={oshLegends} side="left">
                {spanOfControl}
              </ToolTip>
            </span>
            <span
              className={`row to-be font-util ${
                spanOfControlToBe === spanOfControl && noOfChanges === 0
                  ? ""
                  : colorCodeSpanControlToBe
              }`}
            >
              {spanOfControlToBe === spanOfControl && noOfChanges === 0 ? (
                "N/A"
              ) : (
                <ToolTip message={oshLegends} side="left">
                  {spanOfControlToBe}
                </ToolTip>
              )}
              {directionOfChangeSpanOfControl}
            </span>
          </div>
          <div className="header-row">
            <span className="row parameter">
              <ToolTip
                message={compressionDefinition}
                source={compressionImage}
                side="left"
              >
                Management Level COMPRESSION
              </ToolTip>
            </span>
            <span className={`row as-is font-util ${colorCodeCompression}`}>
              <ToolTip message={oshLegends} side="left">
                {jgCompression.toFixed(2)}
              </ToolTip>
            </span>
            <span
              className={`row to-be font-util ${
                compression === jgCompression && noOfChanges === 0
                  ? ""
                  : colorCodeCompressionTobe
              }`}
            >
              {compression === jgCompression && noOfChanges === 0 ? (
                "N/A"
              ) : (
                <ToolTip message={oshLegends} side="left">
                  {compression.toFixed(2)}
                </ToolTip>
              )}
              {directionOfChangeCompression}
            </span>
          </div>
          <div className="header-row">
            <span className="row parameter">
              <ToolTip
                message={orgLayerDefinition}
                source={orgLayerImage}
                side="left"
              >
                Organisation Layer of Direct reports
              </ToolTip>
            </span>
            <span className={`row as-is font-util ${colorCodeMaxLayer}`}>
              <ToolTip message={oshLegends} side="left">
                {maxNoOfLayers}
              </ToolTip>
            </span>
            <span
              className={`row to-be font-util ${
                maxNoOfLayersToBe === maxNoOfLayers && noOfChanges === 0
                  ? ""
                  : colorCodeMaxLayer
              }`}
            >
              {maxNoOfLayersToBe === maxNoOfLayers && noOfChanges === 0 ? (
                "N/A"
              ) : (
                <ToolTip message={oshLegends} side="left">
                  {maxNoOfLayersToBe}
                </ToolTip>
              )}
            </span>
          </div>
          {warningMessage}
        </div>
      </section>
    </div>
  );
};

export default OshTable;
