import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Button from "../../components/button/Button";
import {
  HROLJobEvaluationForLMPage,
  welcomePageSecondLink,
} from "../../utils/utils";

const Instructions = (props) => {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const onClickHandler = () => {
    setIsChecked(!isChecked);
  };
  return (
    <section className="section-welcome">
      <div className="title-bar">
        <span className="icon-box util-bgc"></span>
        <h1 className="title-welcome">
          Welcome to EVA -{" "}
          <span className="subtitle-welcome">
            Your guide to prepare Management Levels for new and changed
            positions​
          </span>
        </h1>
      </div>
      <div className="instructions">
        <article className="paragraph-container">
          <h4 className="content-title">EVA is here to help you</h4>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Test appropriate Management Levels (Job Groups) 3-5 for your team.
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Check the impact of changes on your team structure health.
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Generate required documentation to request the implementation of
              the changes via Workday and/or HR Advice.
            </p>
          </span>

          <h4 className="content-title">Scope of EVA</h4>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              All your direct report positions are displayed but grading changes
              can only be prepared for Management Levels 3-5.​
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Any other positions can be removed from the team for modelling
              purposes, but these changes follow a different implementation
              process. To find out more, {" "}
              <Link
                to={{
                  pathname: HROLJobEvaluationForLMPage,
                }}
                target="_blank"
              >
                <span className="welcome-helper-link"> 🔗 Click here.</span>
              </Link>
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              A maximum of three changes/new positions in your team can be
              modelled. For more than three changes, please contact HR Advice as
              this scale of change typically relates to (small) reorganisations.
            </p>
          </span>
          <article className="rules-list">
            <p className="heading">​ Before you start</p>
            <span className="bullet">
              <span className="outcome-arrow-welcome">◉</span>
              <p className="paragraph-two">
                Prepare job descriptions for any Management Level 3-5 positions
                you want to change or create. You will be requested to upload
                these job descriptions as Word documents.​
              </p>
            </span>
            <span className="bullet">
              <span className="outcome-arrow-welcome">◉</span>
              <p className="paragraph-two">
                Your Line Manager will be receiving a copy of the proposed
                changes and the findings of EVA. Consult your Line Manager as
                appropriate before proceeding with next steps.​​
              </p>
            </span>
            <span className="bullet">
              <span className="outcome-arrow-welcome">◉</span>
              <p className="paragraph-two">
                A guide for Line Managers on making Team Changes is available
                <Link
                  to={{
                    pathname: welcomePageSecondLink,
                  }}
                  target="_blank"
                >
                  {" "}
                  <span className="welcome-helper-link">🔗 Here.</span>
                </Link>
                 
              </p>
            </span>
          </article>

          <h4 className="content-title">When you are done</h4>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Depending on the outcomes of your modelling, you are encouraged to
              consult your Line Manager on the changes before proceeding with
              the next steps.
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome">◉</span>
            <p className="paragraph-two">
              Remember to action your changes in Workday and/or contact HR
              Advice to agree on next steps.
            </p>
          </span>
          <span className="bullet bullet-margin">
            <span className="outcome-arrow-welcome exclaim-icon" style={{color: "#dd1d21"}}>！</span>
            <h4 className="special-content-title" >
              Note: EVA doesn’t automatically trigger changes in Workday.​
            </h4>
          </span>
          <p className="paragraph" style={{ fontWeight: "bold" }}>
            For any related queries, please use Quick Links to access the EVA
            User Guide. Find more through the Help option in the top bar or
            reach out to HR Advice.​ ​​ ​
          </p>
        </article>
        <section className="buttons">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              history.push("/home");
            }}
          >
            <div className="form-group">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={isChecked}
                onChange={() => {}}
                onClick={onClickHandler}
              />
              <label htmlFor="check" className="form-label-check">
                I confirm that I have read the instructions
              </label>
            </div>
            <Button disabled={!isChecked}>Proceed</Button>
          </form>
        </section>
      </div>
    </section>
  );
};

export default Instructions;
