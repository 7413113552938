
import document from "../../../assets/document.png";


const DocumentLinkIcon = () => {
  return (
    <div className="link-icon">
      <img src = {document} alt = "link-icon" style={{height : "100%",width : "100%"}}/>
      </div>
  );
};

export default DocumentLinkIcon;