import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { onAddNew } from "../../app/features/authSlice";
import {
  onAddNewPosition,
  onChangePosition,
  onHideLink,
} from "../../app/features/lineManagerSlice";
import axios from "axios";
import Spinner from "../../components/spinner/Spinner";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import AiHeader from "../../components/ai-header/aiHeader";
import ReactDOMServer from "react-dom/server";
import success from "../../assets/successIcon.png";
import LineManager from './../line-manager/LineManager';
import { useSelector } from "react-redux";
import { selectAuth } from "../../app/features/authSlice";
const DescriptionPage = (props) => {
  const dispatch = useDispatch();
  const {
    positionTitle,
    jobFamilyGroup,
    jobFamily,
    jobProfile,
    managementLevel,
    location,
    prevStep,
    nextStep,
    isDuplicated,
    isEdited,
    isNewPosition,
    jobDescription,
    reasonForChange,
    coeOfPosition,
    jdLink,
    confidence,
    aiManagementLevel,
    jdParsedTextAi,
    aiResponse,
  } = props;
  const {
    managementLevelOfLineManger
  } = useSelector(selectAuth);
  const [isChecked, setIsChecked] = useState(false);
  const [descriptions, setDescriptions] = useState(null);
  const [coeOfSelectedPosition, setCoeOfSelectedPosition] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updatedJobProfile, setUpdatedJobProfile] = useState(null);
  const [updatedManagementLevel, setUpdatedManagementLevel] = useState(null);
  const [cousinProfiles, setCousinProfiles] = useState(null);
 
//  console.log("job pofile", jobProfile);
  useEffect(() => {
    const fetchDescriptions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/jobdescriptions`,
          {
            jobFamilyGroup: jobFamilyGroup.value,
            jobFamily: jobFamily.value,
            jobProfile: jobProfile.value,
          }
        );
        const costOfEmployementData = await axios.get(
          `${process.env.REACT_APP_API_URL}/coe?managementLevel=${
            managementLevel
              ? updatedManagementLevel
                ? updatedManagementLevel
                : managementLevel
              : 0
          }&location=${location.value}`
        );
        const cousinProfiles = await axios.post(
          `${process.env.REACT_APP_API_URL}/getcousins`,
          {
            jobFamilyGroup: jobFamilyGroup.value,
            jobFamily: jobFamily.value,
            managementLevel: !isDuplicated
              ? parseInt(aiResponse.data.AI_EVA_Retraining_JG.split("")[2])
              : managementLevel,
          }
        );
        const responses = await Promise.all([
          response,
          costOfEmployementData,
          cousinProfiles,
        ]);
        const results = await responses[0].data;
        const coeResult = await responses[1].data;
        const cousins = await responses[2].data;
        const validCousins = await cousins.filter(
          (v) => !v.Job_Profile_With_Job_ID.includes(`00000000`)
        );
        const data = [];
        let coe = null;
        if (coeResult || coeResult.length !== 0) {
          coe = parseInt(coeResult[0].Indicative_COE_Std_Pay_Structure);
        }

        results.forEach((element) => {
          let managementLevelOfResults = parseInt(
            element.Management_Level_For_COE_Same_JG_Flagging
          );
         
          let jobProfileWithIdResult = element.Job_Profile_With_Job_ID;
         
          if ( 
            ((managementLevelOfResults === managementLevel && 
              (managementLevelOfResults !== managementLevelOfLineManger)) &&
              !jobProfileWithIdResult.includes(`00000000`)) ||
          ( (managementLevelOfResults === managementLevel - 1 && 
            (managementLevelOfResults !== managementLevelOfLineManger)) &&
              !jobProfileWithIdResult.includes(`00000000`)) ||
            ((managementLevelOfResults === managementLevel + 1 &&
              (managementLevelOfResults !== managementLevelOfLineManger)) &&
              !jobProfileWithIdResult.includes(`00000000`)) ||
            (!isDuplicated &&
              managementLevelOfResults ===
                parseInt(aiResponse.data.AI_EVA_Retraining_JG.split("")[2]) &&
              confidence !== "low")
          ) {
            data.push(element);
            // console.log("mofreesults", managementLevelOfResults, managementLevelOfLineManger, managementLevel);
            // console.log("data",data);
            // console.log("element",element)
          }
        });
        setDescriptions(data);
        setCoeOfSelectedPosition(coe);
        setCousinProfiles(validCousins);
        setIsLoading(false);
      } catch (error) {
        // console.log(error);
        if (error) {
          setError("No details found for the selected job Profile");
        }
        setIsLoading(false);
      }
    };
    fetchDescriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedManagementLevel]);

  const getDescriptionText = (description, symbol, bullet) => {
    let textList = null;
    if (description) {
      const lists = description.split(`${symbol}`);
      textList = lists.map((list, index) => {
        return (
          <span className="safe" key={index}>
            {index === 0 ? "" : <span className="dot">{bullet}</span>}{" "}
            {getDescriptionTextForList(list, "•", "➥", "o")}
          </span>
        );
      });
    }
    return textList;
  };

  const getDescriptionTextForList = (description, symbol, bullet, symbol1) => {
    let textList = null;
    if (description) {
      const lists = description.split(symbol || symbol1);
      textList = lists.map((list, index) => {
        return (
          <span className="safe" key={index}>
            {index === 0 ? "" : <span className="dot-arrow">{bullet}</span>}{" "}
            {list}
          </span>
        );
      });
    }
    return textList;
  };

  let content = (
    <div className="loader-container">
      <Spinner />
    </div>
  );

  let titleText = null;
  if (isDuplicated) {
    titleText = "Position duplicated in EVA";
  } else if (isEdited) {
    titleText = `Position ${props.positionId} updated in EVA`;
  } else {
    titleText = "New position created in EVA";
  }

  const getNewId = uuid();

  const getModifiedJobTitle = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  const progressChart = ReactDOMServer.renderToStaticMarkup(
    <div className="bar-box">
      <div className="progress-bar">Change or create new position</div>
      <div className="progress-bar" style={{ background: "#fbce07" }}>
        Position updated / Created in EVA
      </div>
      <div className="progress-bar" style={{ paddingTop: "22px" }}>
        Finalise Modelling
      </div>
      <div className="progress-bar">Trigger your changes in Workday</div>
    </div>
  );

  const confirmHandler = (e) => {
    e.preventDefault();
    let file = null;
    if (isEdited || isNewPosition) {
      file = URL.createObjectURL(jobDescription);
    }

    if (isDuplicated || isNewPosition) {
      dispatch(
        onAddNewPosition({
          id: getNewId,
          isManager: false,
          showBlue: props.isManager,
          jeId: 52,
          positionUnfilled: false,
          positionId: props.positionId ? props.positionId : getNewId,
          positionTitle: isNewPosition
            ? getModifiedJobTitle(positionTitle)
            : positionTitle,
          initialManagementLevel: managementLevel,
          ManagementLevel: updatedManagementLevel
            ? updatedManagementLevel
            : managementLevel,
          editAndDeleteAllowed: true,
          totalNoOfSameJg: null,
          spanOfControl: null,
          compression: null,
          maxNoOfLayers: null,
          jobFamilyGroup: jobFamilyGroup.value,
          jobFamily: jobFamily.value,
          jobProfile: updatedJobProfile ? updatedJobProfile : jobProfile.value,
          location: location.value,
          coeOfPosition: coeOfSelectedPosition,
          isRemoved: false,
          isActionNotAllowed: false,
          isChanged: isEdited,
          isDuplicated: isDuplicated,
          coeDelta: null,
          comments: reasonForChange,
          attachment: file,
          jdUrl: isNewPosition ? jdLink : null,
          undo: false,
          isNewPosition: isNewPosition,
          aiManagementLevel: isNewPosition ? aiManagementLevel : null,
          confidence: confidence,
          jdParsedTextAi,
          aiResponse: isNewPosition ? aiResponse : null,
          cousinProfiles: isNewPosition ? cousinProfiles : null,
        })
      );
      dispatch(onAddNew());
    } else if (isEdited) {
      dispatch(
        onChangePosition({
          id: getNewId,
          isManager: false,
          showBlue: props.isManager,
          jeId: 52,
          positionUnfilled: props.isUnfilled,
          positionId: +props.positionId,
          positionTitle: getModifiedJobTitle(positionTitle),
          initialManagementLevel: managementLevel,
          ManagementLevel: updatedManagementLevel
            ? updatedManagementLevel
            : managementLevel,
          editAndDeleteAllowed: true,
          totalNoOfSameJg: null,
          spanOfControl: null,
          compression: null,
          maxNoOfLayers: null,
          jobFamilyGroup: jobFamilyGroup.value,
          jobFamily: jobFamily.value,
          jobProfile: updatedJobProfile ? updatedJobProfile : jobProfile.value,
          location: location.value,
          coeOfPosition: isEdited
            ? coeOfSelectedPosition - coeOfPosition
            : coeOfSelectedPosition,
          isRemoved: false,
          isChanged: isEdited,
          isDuplicated: isDuplicated,
          isActionNotAllowed: false,
          coeDelta: null,
          comments: reasonForChange,
          attachment: file,
          jdUrl: jdLink,
          undo: false,
          isNewPosition: isNewPosition,
          aiManagementLevel: aiManagementLevel,
          confidence: confidence,
          jdParsedTextAi,
          aiResponse,
          cousinProfiles,
        })
      );
    }
    const customButtonClasses = Swal.mixin({
      customClass: {
        confirmButton: "change-button warning",
        container:'swal-wide'
      },
      buttonsStyling: false,
    });
    customButtonClasses.fire({
      imageUrl: success,
      imageWidth: 75,
      imageHeight: 75,
      imageAlt: "success",
      html: progressChart,
      title: titleText,
      showConfirmButton: true,
      width: "36%",
    }).then((result) => {
      nextStep(e);
      dispatch(onHideLink());
    });
  };

  const capitalizeJobTitle = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onClickHandler = () => {
    setIsChecked(!isChecked);
  };

  const getDescriptionSummaryText = (description) => {
    let textList = null;
    if (description) {
      const lists = description.split("#");
      textList = lists.map((list, index) => {
        return list ? (
          <span className="safe" key={index}>
            <p className="description-summary">
              {getDescriptionTextForList(list, "•", "➔")}
            </p>
          </span>
        ) : null;
      });
    }
    return textList;
  };

  let descriptionStyles = "descriptions";
  let descriptionListStyles = "description-list ";
  if (descriptions && descriptions.length > 2) {
    descriptionStyles = "description-optional";
    descriptionListStyles = "description-list-optional";
  }

  if (!isLoading && descriptions && descriptions.length !== 0) {
    content = (
      <div className="description-container">
        <React.Fragment>
          <div className="description-title">
            <p className="paragraph">
              Your selected Job Profile is highlighted below. In case EVA
              analysis suggests a different Management Level, the relevant
              profiles are highlighted and a comment is provided.<br></br>
              Please validate your choice. You can select or change the level by
              clicking on the appropriate profile. Once done, please confirm at
              the bottom of the screen and click next.
            </p>
          </div>
          <div className="description-title">
            <h4 className="content-title">
              {capitalizeJobTitle(jobFamily.value)}
            </h4>
            {getDescriptionSummaryText(descriptions[0].Job_Description_Summary)}
          </div>
          <div className={descriptionListStyles}>
            {descriptions.map((description) => {
              let aiHeader = null;
              if (
                (managementLevel !==
                  +description.Management_Level_For_COE_Same_JG_Flagging ||
                  managementLevel === props.aiManagementLevel ||
                  props.aiManagementLevel !==
                    +description.Management_Level_For_COE_Same_JG_Flagging) &&
                !props.isDuplicated &&
                confidence !== "low" &&
                (confidence === "high" || confidence === "medium") &&
                props.aiManagementLevel > managementLevel || //added another condtion for duplicate - popup display
                 (props.isDuplicated  && managementLevel === +description.Management_Level_For_COE_Same_JG_Flagging)   ) {
                aiHeader = (
                  <AiHeader
                  isDuplicated = {props.isDuplicated}
                    aiManagementLevel={props.aiManagementLevel}
                    managementLevel={parseInt(
                      description.Management_Level_For_COE_Same_JG_Flagging
                    )}
                    selectedManagementLevel={managementLevel}
                    jobProfile={description.Job_Profile_With_Job_ID}
                  />
                );
              } else if (
                managementLevel ===
                  +description.Management_Level_For_COE_Same_JG_Flagging &&
                props.aiManagementLevel !==
                  +description.Management_Level_For_COE_Same_JG_Flagging
              ) {
                aiHeader = null;
              }

              let borderOfDescription = "";
              if (
                managementLevel !== props.aiManagementLevel &&
                managementLevel ===
                  +description.Management_Level_For_COE_Same_JG_Flagging &&
                !isDuplicated &&
                (confidence === "high" || confidence === "medium") &&
                props.aiManagementLevel > managementLevel
              ) {
                borderOfDescription = "red-border";
              } else if (
                managementLevel !==
                  +description.Management_Level_For_COE_Same_JG_Flagging &&
                +description.Management_Level_For_COE_Same_JG_Flagging ===
                  props.aiManagementLevel &&
                !isDuplicated &&
                (confidence === "high" || confidence === "medium") &&
                props.aiManagementLevel > managementLevel
              ) {
                borderOfDescription = "blue-border";
              }

              return (
                <div
                  className={`${descriptionStyles} ${borderOfDescription}`}
                  key={description.JE_Id}
                  onClick={() => {
                    if (
                      (!isDuplicated 
                        // &&
                        // parseInt(
                        //   description.Management_Level_For_COE_Same_JG_Flagging
                        // ) !== 6
                        )
                         ||
                      (aiManagementLevel === 6 && confidence !== "low")
                    ) {
                      props.updateSelectedJobProfile(
                        description.Job_Profile_With_Job_ID
                      );
                      setUpdatedJobProfile(description.Job_Profile_With_Job_ID);

                      setUpdatedManagementLevel(
                        parseInt(
                          description.Management_Level_For_COE_Same_JG_Flagging
                        )
                      );
                    }
                  }}
                >
                  <h4
                    className={`description-title-position ${
                      (description.Job_Profile_With_Job_ID ===
                        jobProfile.value &&
                        !updatedJobProfile) ||
                      description.Job_Profile_With_Job_ID === updatedJobProfile
                        ? "description-border"
                        : ""
                    }`}
                  >
                    {description.Job_Profile_With_Job_ID}
                  </h4>
                  <div className={`description`} key={description.JE_Id}>
                    {aiHeader}
                    <p className="description__text">
                      {getDescriptionText(description.Job_Description, "#", "")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
        <div className="description-buttons">
          <div className="confirm-buttons">
            <div className="confirm-button">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={isChecked}
                onChange={() => {}}
                onClick={onClickHandler}
              />
              <label htmlFor="check" className="form-label">
                PROCEED WITH MY SELECTION, I CONFIRM THAT IT MATCHES
                EXPECTATIONS OF THE POSITION
              </label>
            </div>
            <div>
              <Button disabled={!isChecked} onClick={confirmHandler}>
                Next
              </Button>
            </div>
          </div>

          <div className="description-button">
            <Button onClick={prevStep}>
              {isEdited || isNewPosition
                ? "I need to reselect the job profiles"
                : "Go Back to Change Location"}
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (!isLoading && error.trim().length !== 0) {
    content = (
      <div className="error-box-job-description">
        <h2 className="error-message-job-description">{error}</h2>
        <Button onClick={prevStep}>GO BACK</Button>
      </div>
    );
  }

  return content;
};

export default DescriptionPage;
