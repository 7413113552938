import React, { useRef, useEffect } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "./components/spinner/Spinner";

import MainNavigation from "./containers/navigation/MainNavigation";
import AuthLogout from "./pages/auth/Auth";

import Home from "./pages/home/Home";

import EditPosition from "./pages/position-edit/EditPosition";
import NewPosition from "./pages/position-new/NewPosition";
import Welcome from "./pages/welcome/Welcome";

import DuplicatePosition from "./pages/duplicate/DuplicatePosition";
import OutcomeSheet from "./pages/outcome/OutcomeSheet";

import { useAuth } from "./services";
import { loginAsync, selectAuth } from "./app/features/authSlice";
import PrivateRoute from "./components/private-route/PrivateRoute";
import AutoLogout from "./components/auto-logout/AutoLogout";

import Auth from "./components/auth-callback/AuthCallback";

const App = (props) => {
  const nodeRef = useRef();
  const { authService } = useAuth();
  const dispatch = useDispatch();
  const { error, positionId } = useSelector(selectAuth);

  const login = async () => {
    authService.authorize();
  };

  useEffect(() => {
    if (authService.isAuthenticated()) {
      const fetchTeamDetails = async () => {
        await Promise.resolve(
          dispatch(loginAsync(authService.getUser()["sub"]))
        );
      };

      fetchTeamDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let routes = (
    <Switch>
      <Route exact path="/">
        <Auth login={login} />
      </Route>
      <Route exact path="/logout">
        <AuthLogout login={login} />
      </Route>
      <Redirect to="/" />
    </Switch>
  );

  if (authService.isPending()) {
    routes = <Spinner />;
  }

  if (authService.isAuthenticated()) {
    routes = (
      <AutoLogout>
        {!error && positionId && <MainNavigation />}
        <Switch>
          <Route path="/" exact>
            <Welcome />
          </Route>
          <PrivateRoute path="/home" exact Component={Home} />
          <PrivateRoute path="/home/new" exact Component={NewPosition} />
          <PrivateRoute
            path="/home/change/:positionId"
            exact
            Component={EditPosition}
          />

          <PrivateRoute
            path="/home/duplicate/:positionId"
            exact
            Component={DuplicatePosition}
          />
          <PrivateRoute path="/outcome" exact Component={OutcomeSheet} />
          <Redirect to="/" />
        </Switch>
      </AutoLogout>
    );
  }

  return <main ref={nodeRef}>{routes}</main>;
};

export default withRouter(App);
