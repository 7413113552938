import React from "react";

const Logo = () => {
  return (
    <div className="logo-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192.756 152.756"
        className="logo"
      >
        <g fillRule="evenodd" clipRule="evenodd">
          <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
          <path
            d="M52.885 141.639l-3.221-26.088c-8.528-6.064-15.098-11.055-22.994-16.803-3.728-27.48 3.158-42.388 13.265-56.538C52.506 26.228 72.72 14.289 96.914 14.289c24.195.252 44.472 12.444 56.412 28.426 11.18 15.161 17.182 34.238 12.822 56.791l-21.793 16.045-3.223 26.088H106.58c-2.148 1.707-5.053 3.285-9.666 3.412-4.548-.127-7.391-1.705-9.728-3.412H52.885z"
            fill="#cc2229"
          />
          <path
            d="M96.915 105.443c-1.832-24.321-2.337-54.327-3.853-78.394-2.59-.632-6.254-.442-9.286.063-3.032.948-5.243 1.769-7.833 3.222 4.738 23.12 10.234 52.557 14.909 75.488-6.38-22.299-14.909-50.22-21.225-72.456-3.032.632-6.001 2.337-8.148 4.106-2.274 1.769-3.601 3.096-5.686 6.254 9.159 19.583 20.15 44.535 29.374 63.991C74.49 90.029 61.54 66.72 50.801 49.159c-2.084 1.832-3.664 3.159-5.749 6.696-1.769 2.843-2.653 6.191-3.032 9.413 11.75 14.34 25.773 31.522 37.586 45.797-12.317-11.876-27.479-27.163-39.67-39.039-1.2 2.4-1.958 4.422-2.337 8.401-.442 3.853.316 8.464 1.137 11.244l23.815 17.688 2.527 19.646 26.216.189c1.895 2.211 2.526 3.348 5.622 3.537 2.968-.189 4.675-2.021 5.433-3.537H128.5l2.652-19.709 23.627-17.562c.883-2.905 1.451-7.391 1.137-11.244-.379-4.169-1.137-6.254-2.148-8.465-12.254 11.813-27.479 27.101-39.67 38.976 11.875-14.15 25.773-31.396 37.648-45.861-.631-3.221-1.389-6.507-3.285-9.349-2.211-3.538-3.475-4.864-5.496-6.759-10.801 17.498-23.941 40.998-34.617 58.558 9.477-19.519 20.215-44.471 29.502-63.8-2.021-3.096-3.475-4.675-5.496-6.317-2.4-1.832-5.369-3.537-8.275-4.232-6.633 22.172-14.781 50.346-21.225 72.455 4.484-22.931 10.107-52.115 14.592-75.171-2.273-1.643-4.484-2.464-7.455-3.348-3.41-.632-6.758-.758-9.412-.316-1.768 24.256-1.833 54.199-3.664 78.393z"
            fill="#f6d330"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
