
import video from "../../../assets/video.png";


const VideoLinkIcon = () => {
  return (
    <div className="link-icon">
      <img src = {video} alt = "link-icon" style={{height : "100%",width : "100%"}}/>
      </div>
  );
};

export default VideoLinkIcon;