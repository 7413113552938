import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { logout, resetOsh } from "../../app/features/authSlice";
import { useAuth } from "../../services/AuthContext";

const AutoLogout = (props) => {
  const timeout = 900000;
  const [remaining, setRemaining] = useState(timeout);

  const dispatch = useDispatch();
  const { authService } = useAuth();
  const history = useHistory();

  const handleOnIdle = () => {
    authService.logout();
    dispatch(logout());
    dispatch(resetOsh());
    history.push("/logout");
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());
    let timer = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approxeq = (v1, v2, epsilon = 1000) => Math.abs(v1 - v2) <= epsilon;

  if (approxeq(remaining, 300000)) {
    const customButtonClasses = Swal.mixin({
      customClass: {
        confirmButton: "change-button confirm",
        cancelButton: "change-button cancel",
      },
      buttonsStyling: false,
    });
    customButtonClasses
      .fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">You have been idle for a long time.You will be logged out in 5 minutes</p>`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Logout",
        cancelButtonText: "Stay",
      })
      .then((result) => {
        if (result.isConfirmed) {
          authService.logout();
          dispatch(logout());
          dispatch(resetOsh());
          history.push("/logout");
        } else if (result.isDismissed) {
          reset();
        }
      });
  }

  // const msToTime = (duration) => {
  //   var milliseconds = parseInt(duration % 1000),
  //     seconds = parseInt((duration / 1000) % 60),
  //     minutes = parseInt((duration / (1000 * 60)) % 60),
  //     hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  //   hours = hours < 10 ? "0" + hours : hours;
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   seconds = seconds < 10 ? "0" + seconds : seconds;

  //   return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  // };

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AutoLogout;
