import React, { useState } from "react";

const ToolTip = (props) => {
  const [displayToolTip, setDisplayToolTip] = useState(
    props.displayToolTip ? props.displayToolTip : false
  );

  const hideToolTip = () => {
    setDisplayToolTip(false);
  };

  const showToolTip = () => {
    setDisplayToolTip(true);
  };

  let style = "tooltip-right";
  if (props.side === "left") {
    style = "tooltip-left";
  } else if (props.side === "top") {
    style = "tooltip-top";
  } else if (props.side === "top-right") {
    style = "tool-tip-right";
  }

  return (
    <span className="tool-tip" onMouseLeave={hideToolTip}>
      {displayToolTip && (
        <div className={`tooltip-bubble ${style}`}>
          <div
            className={`tooltip-message ${
              props.side === "top" || props.side === "top-right"
                ? "tooltip-width"
                : ""
            }`}
          >
            {props.source ? (
              <img src={props.source} alt="span of control" />
            ) : null}
            {props.message}
          </div>
        </div>
      )}
      <span className="tooltip-trigger" onMouseOver={showToolTip}>
        {props.children}
      </span>
    </span>
  );
};

export default ToolTip;
