import { Link } from "react-router-dom";
import WelcomePageLink from "../../components/links/welcome-page-link/WelcomePageLink";
import {
  allAboutJobProfiles,
  hrAdviceRepresentative,
  jobTitleNamingConventions,
  lineManagerGuidanceOnManagementLevels,
  systemSupportLink,
  managementLevelProbabilityAnalysis,
} from "../../utils/utils";
import DocumentLinkIcon from "../../components/icons/link/documentLinkIcon";

const UseFullLinks = (props) => {
  return (
    <div className="links-job-evaluation">
      <h2 className="links-header-position">FURTHER INFORMATION</h2>
      <Link
        to={{
          pathname: jobTitleNamingConventions,
        }}
        target="_blank"
      >
            <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text">JOB TITLE NAMING CONVENTION</span>
            </div>
      
      </Link>
      <Link
        to={{
          pathname: allAboutJobProfiles,
        }}
        target="_blank"
      >
        
        <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text">ALL ABOUT JOB PROFILES</span>
        </div>
      </Link>

      <Link
        to={{
          pathname: lineManagerGuidanceOnManagementLevels,
        }}
        target="_blank"
      >
        
        <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text"> LINE MANAGER GUIDANCE ON MANAGEMENT LEVELS</span>
        </div> 
      </Link>
      
      <Link
        to={{
          pathname: managementLevelProbabilityAnalysis,
        }}
        target="_blank"
      >
           <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text">  MANAGEMENT LEVEL PROBABILITY ANALYSIS</span>
            </div> 
    
      </Link>


      <Link
        to={{
          pathname: hrAdviceRepresentative,
        }}
        target="_blank"
      >
        <WelcomePageLink>HR ADVICE REPRESENTATIVE</WelcomePageLink>
      </Link>

      <Link
        to={{
          pathname: systemSupportLink,
        }}
        target="_blank"
      >
        <WelcomePageLink>HR SYSTEMS SUPPORT</WelcomePageLink>
      </Link>
    </div>
  );
};

export default UseFullLinks;
