import React from "react";

const JobDescription = () => {
  return (
    <div className="job-description-icon">
      <svg
        viewBox="0 0 140 140"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="70.418" cy="70" fill="#efc84a" r="64" />
        <path
          d="m70.4179764 133.9999695c30.7127686 0 56.361084-21.6384277 62.5605392-50.4988403l-33.5627365-33.562809-17.0700683-17.0700074h-40.9199219v72.789978l28.324707 28.3248291c.2233887.0023232.4436035.0168496.6674805.0168496z"
          opacity=".1"
        />
        <path
          d="m82.3470917 32.8715935h-40.9240341v72.790348h57.989685v-55.7246933z"
          fill="#e1e6e9"
        />
        <path
          d="m82.3470917 49.9372482h17.0656509l-17.0656509-17.0656547z"
          fill="#ebf0f3"
        />
        <g fill="#d5d6db">
          <path d="m49.460285 63.436878h8.25v1.990051h-8.25z" />
          <path d="m60.280293 63.436878h12.959961v1.990051h-12.959961z" />
          <path d="m75.810257 63.436878h15.070007v1.990051h-15.070007z" />
          <path d="m49.460285 70.666916h15.23999v1.979981h-15.23999z" />
          <path d="m67.270279 70.666916h12.880005v1.979981h-12.880005z" />
          <path d="m82.730301 70.666916h8.149963v1.979981h-8.149963z" />
          <path d="m49.460285 77.886887h23.779968v1.980042h-23.779968z" />
          <path d="m75.810257 77.886887h15.070007v1.980042h-15.070007z" />
          <path d="m49.460285 85.106918h9.299988v1.98999h-9.299988z" />
          <path d="m61.33028 85.106918h6.27002v1.98999h-6.27002z" />
          <path d="m70.170303 85.106918h11.269959v1.98999h-11.269959z" />
          <path d="m84.010269 85.106918h6.869995v1.98999h-6.869995z" />
          <path d="m49.460285 92.336899h13.960022v1.979981h-13.960022z" />
          <path d="m65.99025 92.336899h8.240051v1.979981h-8.240051z" />
          <path d="m76.800247 92.336899h14.080017v1.979981h-14.080017z" />
        </g>
        <g fill="#d15241">
          <path d="m59.2415848 56.2018089 1.0253906-.140625c.0273438.65625.1503906 1.1054688.3691406 1.3476562s.5214844.3632812.9082031.3632812c.2851562 0 .53125-.0654297.7382812-.1962891s.3496094-.3085938.4277344-.5332031.1171875-.5830078.1171875-1.0751953v-5.9179688h1.1367188v5.8535156c0 .71875-.0869141 1.2753906-.2607422 1.6699219s-.4492188.6953125-.8261719.9023438-.8193359.3105469-1.3271484.3105469c-.7539062 0-1.3310547-.2167969-1.7314453-.6503906s-.5927734-1.0781249-.5771484-1.9335937z" />
          <path d="m65.4759598 54.4557152c0-1.4257812.3828125-2.5419922 1.1484375-3.3486328s1.7539062-1.2099609 2.9648438-1.2099609c.7929688 0 1.5078125.1894531 2.1445312.5683594s1.1220703.9072266 1.4560547 1.5849609.5009766 1.4462891.5009766 2.3056641c0 .8710938-.1757812 1.6503906-.5273438 2.3378906s-.8496094 1.2080078-1.4941406 1.5615234-1.3398438.5302734-2.0859375.5302734c-.8085938 0-1.53125-.1953125-2.1679688-.5859375s-1.1191406-.9238281-1.4472656-1.5996094-.4921875-1.390625-.4921875-2.1445312zm1.171875.0175781c0 1.0351562.2783203 1.8505859.8349609 2.4462891s1.2548828.8935547 2.0947266.8935547c.8554688 0 1.5595703-.3007812 2.1123047-.9023438s.8291016-1.4550781.8291016-2.5605469c0-.6992188-.1181641-1.3095703-.3544922-1.8310547s-.5820312-.9257812-1.0371094-1.2128906-.9658203-.4306641-1.5322266-.4306641c-.8046875 0-1.4970703.2763672-2.0771484.8291016s-.8701172 1.475586-.8701172 2.7685547z" />
          <path d="m75.1087723 58.6393089v-8.5898438h3.2226562c.65625 0 1.1826172.0869141 1.5791016.2607422s.7070312.4414062.9316406.8027344.3369141.7392578.3369141 1.1337891c0 .3671875-.0996094.7128906-.2988281 1.0371094s-.5.5859375-.9023438.7851562c.5195312.1523438.9189453.4121094 1.1982422.7792969s.4189453.8007812.4189453 1.3007812c0 .4023438-.0849609.7763672-.2548828 1.1220703s-.3798828.6123047-.6298828.7998047-.5634766.3291016-.9404297.4248047-.8388672.1435547-1.3857422.1435547zm1.1367187-4.9804687h1.8574219c.5039062 0 .8652344-.0332031 1.0839844-.0996094.2890625-.0859375.5068359-.2285156.6533203-.4277344s.2197266-.4492188.2197266-.75c0-.2851562-.0683594-.5361328-.2050781-.7529297s-.3320312-.3652344-.5859375-.4453125-.6894531-.1201172-1.3066406-.1201172h-1.716797zm0 3.9667969h2.1386719c.3671875 0 .625-.0136719.7734375-.0410156.2617188-.046875.4804688-.125.65625-.234375s.3203125-.2685547.4335938-.4775391.1699219-.4501953.1699219-.7236328c0-.3203125-.0820312-.5986328-.2460938-.8349609s-.3916016-.4023438-.6826172-.4980469-.7099609-.1435547-1.2568359-.1435547h-1.9863282z" />
        </g>
        <path
          d="m88.358284 82.870972h3.498309v5.441814h-3.498309z"
          fill="#2f4859"
          transform="matrix(.707 -.707 .707 .707 -34.131 88.785)"
        />
        <path
          d="m69.6700058 65.4399338c-5.3829651 5.3829651-5.3829422 14.1074219-.0041199 19.4862442 5.382988 5.3829956 14.1074448 5.3830185 19.4904099.0000534 5.3830719-5.3830719 5.3830414-14.1075287.0000534-19.4905167-5.3788223-5.3788223-14.1032791-5.3788452-19.4863434.0042191zm17.5864105 17.5864181c-4.3330231 4.3330231-11.3575516 4.3329926-15.6906281-.0000763-4.3289032-4.3289032-4.3289261-11.3534393.004097-15.6864624 4.3331223-4.3331223 11.3576584-4.3330956 15.6865616-.0041962 4.3330689 4.3330765 4.3330918 11.357605-.0000305 15.6907349z"
          fill="#3a556a"
        />
        <path
          d="m92.4091949 84.0336685-3.8599319 3.8599319c-.2649765.2649765-.2649994.6946106 0 .9596176l12.3479156 12.3479156c.2649765.2649765.6946411.2649765.9596176 0l3.8599243-3.8599319c.2649841-.2649765.2649307-.6945877-.0000458-.9595642l-12.3479155-12.3479156c-.2650071-.2649994-.6945878-.2650299-.9595643-.0000534z"
          fill="#27a2db"
        />
      </svg>
    </div>
  );
};

export default JobDescription;
