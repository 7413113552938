import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { logout, selectAuth, resetOsh } from "../../app/features/authSlice";
import { connectHRLink } from "../../utils/utils";

import { useAuth } from "../../services/AuthContext";
import info from '../../assets/infoIcon.png';

const NavLinks = (props) => {
  const { name } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { authService } = useAuth();
  const history = useHistory();

  const handleOnClick = () => {
    const customButtonClasses = Swal.mixin({
      customClass: {
        confirmButton: "change-button confirm",
        cancelButton: "change-button cancel",
      },
      buttonsStyling: false,
    });
    customButtonClasses
      .fire({
        title: "Are you sure?",
        text: "Once logged out, all the changes will be lost",
        html: `<p style="margin:5px 0;font-size:24px;text-align:center;font-weight:700;">Remember to trigger any finalized modelling outcomes in Workday to effect them</p>`,
        imageUrl: info,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "info",
        showCancelButton: true,
        confirmButtonText: "Logout",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          authService.logout();
          dispatch(logout());
          dispatch(resetOsh());
          history.push("/logout");
        }
      });
  };

  const getInitials = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  };

  return (
    <ul className="nav-links">
      <li>
        <NavLink
          to={{
            pathname: connectHRLink,
          }}
          target="_blank"
        >
          Help
        </NavLink>
      </li>
      <li className="nav-links__right">
        <div onClick={handleOnClick} className="drop-down__button cursor">
          <span className="avatar-title">LOG OUT </span>
        </div>
      </li>
      <li className="nav-links__right">
        <div>
          <div className="drop-down__button ">
            <span className="avatar">{name ? getInitials(name) : "LM"}</span>
            <span className="avatar-title">
              {name ? name : "LINE MANAGER"}{" "}
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default NavLinks;
