import Button from "../../components/button/Button";
import ErrorIcon from "../../components/icons/error/ErrorIcon";
import { connectHRLink } from "../../utils/utils";

const Error = (props) => {
  return (
    <div className="error-container">
      <span className="error-outer-boundary">
        <ErrorIcon />
      </span>
      <span className="error-title">oops! you are not authorized</span>
      <span className="error-text">
        Sorry but you don't have the permission to access this resource.
      </span>
      <span className="error-text">
        Please contact HR for further instructions.
      </span>
      <div className="button-container">
        <Button to={connectHRLink} size="big">
          CONNECT HR
        </Button>
      </div>
    </div>
    // <div className="error-container">
    //   <span className="error-outer-boundary">
    //     <ErrorIcon />
    //   </span>
    //   <span className="error-text">
    //     EVA tool is currently unavailable until March 7, 2022 (00:00 CET) due to
    //     a scheduled maintenance.
    //   </span>
    //   <span className="error-text">
    //     Sorry but you don't have the permission to access this resource.
    //   </span>
    //   <span className="error-text">
    //     Please contact HR for further instructions
    //   </span>
    //   <div className="button-container">
    //     <Button to={connectHRLink} size="big">
    //       CONNECT HR
    //     </Button>
    //   </div>
    // </div>
  );
};

export default Error;
