import Location from "../../components/icons/location/Location";

const ChangedPosition = (props) => {
  const {
    isManager,
    positionTitle,
    positionId,
    jobProfile,
    location,
    isUnfilled,
    isNewPosition,
    isEdited,
    isDuplicated,
    isRemoved,
    showBlue,
  } = props;

  const getJobIdWithProfile = (jobProfile) => {
    if (!jobProfile) return [];
    var i = jobProfile.indexOf("-");
    if (i > 0) {
      return [jobProfile.substring(0, i), jobProfile.substring(i + 1)];
    } else return [jobProfile];
  };

  let border = "";
  if (isRemoved) {
    border = "removed-position";
  } else if (isNewPosition) {
    border = "new-position";
  } else if (isDuplicated) {
    border = "duplicated-position";
  } else if (isEdited) {
    border = "edited-position";
  }

  let background = "";
  if (isManager || showBlue) {
    background = "background";
  }

  let hidden = "";
  if (isDuplicated || isNewPosition) {
    hidden = "hidden";
  }

  return (
    <div className={`changed-position ${border} ${background}`}>
      {isUnfilled ? (
        <span className="unfilled-tag position-alt">Unfilled</span>
      ) : null}
      <div className="individual-contributor-details">
        <p className="outcome-card-text bold-font">{positionTitle}</p>
        <p className={`outcome-card-text ${hidden}`}>
          Position Number : {isNewPosition || isDuplicated ? null : positionId}
        </p>
        <p className="outcome-card-text">
          Job Profile : {getJobIdWithProfile(jobProfile)[0]} -
        </p>
        <p className="outcome-card-text margin-util-2">
          {getJobIdWithProfile(jobProfile)[1]}
        </p>
        <div className="outcome-location-box">
          <Location />
          <p className="outcome-card-text">{location}</p>
        </div>
      </div>
    </div>
  );
};

export default ChangedPosition;
