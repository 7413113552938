import React from "react";

import FormContainer from "../../containers/fom-container/FormContainer";
import UseFullLinks from "../../containers/usefull-links/UseFullLinks";

const NewPosition = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <FormContainer isNewPosition={true} />
        <UseFullLinks />
      </div>
    </React.Fragment>
  );
};

export default NewPosition;
