import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/header/Header";
import Logo from "../../components/logo/Logo";
import NavLinks from "../navlinks/NavLinks";

const MainNavigation = (props) => {
  return (
    <Header>
      <Link to="/">
        <Logo />
      </Link>
      <nav className="main-navigation__nav-links">
        <NavLinks  />
      </nav>
    </Header>
  );
};

export default MainNavigation;
