import { useSelector } from "react-redux";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import ToolTip from "../tooltip/ToolTip";

const AiTable = (props) => {
  const { changes } = useSelector(selectLineManager);

  return (
    <div className="table-ai">
      <section className="section-ai-table">
        <h3 className="ai-header-table">
          EVA ANALYSIS OF MANAGEMENT LEVEL FOR NEW/CHANGED POSITIONS
        </h3>

        {changes.length !== 0 &&
          changes.map((value) => {
            let output = "Inconclusive";
            let background = "ai-parameter-amber";
            // POP UP 1
            if (
              (value.confidence === "high" || value.confidence === "medium") &&
              value.aiManagementLevel > value.initialManagementLevel
            ) {
              //green
              if (
                value.ManagementLevel === value.aiManagementLevel &&
                (value.confidence === "high" || value.confidence === "medium")
              ) {
                output = "Aligned";
                background = "ai-parameter-blue";
              }
              //red
              else if (
                (value.confidence === "high" &&
                  value.aiManagementLevel !== value.ManagementLevel) ||
                (value.confidence === "medium" &&
                  value.aiManagementLevel - value.ManagementLevel === 2 &&
                  value.aiManagementLevel - value.initialManagementLevel === 1)
              ) {
                output = "Misaligned";
                background = "ai-parameter-pink";
              }
              //amber
              else {
                output = "Inconclusive";
                background = "ai-parameter-amber";
              }
            }
            // POP UP 3
            else if (
              (value.confidence === "high" || value.confidence === "medium") &&
              value.aiManagementLevel === value.initialManagementLevel
            ) {
              //green
              if (
                value.ManagementLevel === value.aiManagementLevel &&
                (value.confidence === "high" || value.confidence === "medium")
              ) {
                output = "Aligned";
                background = "ai-parameter-blue";
              }
              //red
              else if (
                value.confidence === "high" &&
                value.aiManagementLevel !== value.ManagementLevel
              ) {
                output = "Misaligned";
                background = "ai-parameter-pink";
              }
              //amber
              else if (
                value.confidence === "medium" &&
                value.aiManagementLevel !== value.ManagementLevel
              ) {
                output = "Inconclusive";
                background = "ai-parameter-amber";
              }
            }
            // POP UP 4
            else if (
              (value.confidence === "high") &&
              value.aiManagementLevel < value.initialManagementLevel
            ) {
              if (
                //green
                value.confidence === "high" &&
                value.ManagementLevel === value.aiManagementLevel
              ) {
                output = "Aligned";
                background = "ai-parameter-blue";
              }
              //amber
              // else if (
              //   value.confidence === "medium" &&
              //   value.aiManagementLevel === value.ManagementLevel
              // ) {
              //   output = "Inconclusive";
              //   background = "ai-parameter-amber";
              // }
              //amber
              else if (
                (value.confidence === "high") &&
                value.aiManagementLevel !== value.ManagementLevel
              ) {
                output = "Inconclusive";
                background = "ai-parameter-amber";
              }
            }
            //POP UP 2
            else if ((value.confidence === "low") || 
            ((value.confidence === "medium") && 
            (value.aiManagementLevel < value.initialManagementLevel))){

              output = "Inconclusive";
              background = "ai-parameter-amber";
            }
            //hover-text
            let hoverMessage = "";
            if (output === "Aligned") {
              hoverMessage = "Most probable Management Level​";
            } else if (output === "Misaligned") {
              hoverMessage = "Different Management Level predicted​";
            } else if (output === "Inconclusive") {
              hoverMessage = "No strong evidence for selected Management Level";
            }

            let content = null;
            if (!value.isDuplicated && !value.isRemoved) {
              content = (
                <div className="ai-table" key={value.id}>
                  <div className="ai-header-row" key={value.id}>
                    <span className="ai-row ai-header-parameter">
                      {value.positionTitle}
                    </span>
                    <span className={`ai-row ai-parameter ${background}`}>
                      <ToolTip message={hoverMessage} side="left">
                        {output}
                      </ToolTip>
                    </span>
                  </div>
                </div>
              );
            }
            return content;
          })}
      </section>
    </div>
  );
};

export default AiTable;
