
import contact from "../../../assets/contact.png";


const LinkIcon = () => {
  return (
    <div className="link-icon">
      <img src = {contact} alt = "link-icon" style={{height : "100%",width : "100%"}}/>
      {/* <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0"
          fill="#ffd400"
        />
        <path
          d="m512 256c0-11.011719-.695312-21.859375-2.046875-32.503906l-104.335937-104.335938-139.09375 29.601563-92.855469 86.90625-67.09375 157.402343 116.878906 116.878907c10.660156 1.351562 21.523437 2.050781 32.546875 2.050781 141.386719 0 256-114.613281 256-256zm0 0"
          fill="#fdba12"
        />
        <path
          d="m313.769531 313.769531c-20.402343 7.792969-43.089843 7.863281-63.535156.203125l-48.417969 48.421875c-14.394531 14.390625-37.816406 14.390625-52.210937 0-14.390625-14.394531-14.390625-37.816406 0-52.210937l48.421875-48.417969 6.132812-6.136719c12.90625-12.90625 33.066406-14.230468 47.472656-3.996094 1.660157 1.179688 3.25 2.507813 4.738282 3.996094 1.488281 1.488282 2.8125 3.074219 3.992187 4.738282 14.410157 10.234374 34.566407 8.910156 47.472657-3.996094l6.136718-6.136719c-4.339844-11.585937-11.136718-22.457031-20.445312-31.761719-9.304688-9.308594-20.175782-16.105468-31.765625-20.445312-20.441407-7.660156-43.128907-7.589844-63.53125.203125-11.386719 4.347656-22.066407 11.078125-31.226563 20.242187l-54.554687 54.554688c-34.882813 34.882812-34.882813 91.640625 0 126.523437 34.882812 34.882813 91.640625 34.882813 126.523437 0l54.554688-54.554687c9.164062-9.164063 15.894531-19.839844 20.242187-31.226563zm0 0"
          fill="#414952"
        />
        <path
          d="m112.449219 399.550781c34.882812 34.882813 91.640625 34.882813 126.523437 0l54.554688-54.554687c9.164062-9.160156 15.894531-19.839844 20.242187-31.226563-20.402343 7.792969-43.089843 7.863281-63.535156.203125l-48.417969 48.421875c-14.394531 14.390625-37.816406 14.390625-52.210937 0-.246094-.25-.476563-.503906-.71875-.757812l-37.15625 37.15625c.246093.25.472656.507812.71875.757812zm0 0"
          fill="#333940"
        />
        <path
          d="m256.371094 255.628906c1.488281 1.484375 2.8125 3.074219 3.996094 4.738282 14.40625 10.238281 34.5625 8.910156 47.472656-3.996094l6.132812-6.136719c-4.339844-11.585937-11.136718-22.457031-20.445312-31.761719-.246094-.25-.496094-.488281-.746094-.730468l-37.164062 37.164062c.253906.238281.507812.472656.753906.722656zm0 0"
          fill="#333940"
        />
        <path
          d="m399.550781 112.449219c-34.882812-34.882813-91.640625-34.882813-126.523437 0l-54.554688 54.554687c-9.164062 9.160156-15.894531 19.839844-20.242187 31.226563 20.402343-7.792969 43.089843-7.863281 63.535156-.203125l48.417969-48.421875c14.390625-14.390625 37.8125-14.390625 52.207031 0 14.394531 14.394531 14.394531 37.816406 0 52.210937l-54.554687 54.554688c-12.90625 12.90625-33.0625 14.230468-47.472657 3.996094-1.660156-1.183594-3.25-2.507813-4.734375-3.996094-1.488281-1.488282-2.816406-3.074219-3.996094-4.738282-14.40625-10.234374-34.566406-8.910156-47.472656 3.996094l-6.132812 6.136719c4.339844 11.585937 11.136718 22.457031 20.445312 31.761719 9.304688 9.308594 20.175782 16.105468 31.761719 20.445312 20.445313 7.660156 43.132813 7.589844 63.535156-.203125 11.386719-4.347656 22.0625-11.078125 31.226563-20.242187l54.554687-54.554688c34.882813-34.878906 34.882813-91.640625 0-126.523437zm0 0"
          fill="#4a7aff"
        />
        <path
          d="m251.632812 251.632812c-14.40625-10.238281-34.5625-8.910156-47.46875 3.996094l-6.136718 6.136719c4.226562 11.28125 10.785156 21.875 19.714844 31.015625l37.164062-37.164062c-1.195312-1.265626-2.289062-2.597657-3.273438-3.984376zm0 0"
          fill="#4a7aff"
        />
        <path
          d="m399.550781 112.449219c-.25-.246094-.507812-.472657-.757812-.71875l-37.15625 37.15625c.253906.242187.507812.472656.757812.71875 14.390625 14.394531 14.390625 37.816406 0 52.210937l-54.554687 54.554688c-12.90625 12.90625-33.066406 14.230468-47.472656 3.992187-1.660157-1.179687-3.25-2.503906-4.738282-3.992187-.246094-.246094-.484375-.5-.722656-.753906l-37.164062 37.164062c.246093.246094.484374.5.730468.746094 9.304688 9.308594 20.175782 16.105468 31.765625 20.445312 20.441407 7.65625 43.128907 7.589844 63.53125-.203125 11.386719-4.347656 22.066407-11.082031 31.226563-20.242187l54.554687-54.554688c34.882813-34.878906 34.882813-91.640625 0-126.523437zm0 0"
          fill="#2864f0"
        />
      </svg> */}
    </div>
  );
};

export default LinkIcon;
